<template>
  <div>
    <div class="bgBox">
      <el-carousel arrow="never" indicator-position="none">
        <el-carousel-item v-for="img in bglist" :key="img.id">
          <img class="img" :src="img.url" alt="" />
        </el-carousel-item>
      </el-carousel>
      <div class="bgTitle">
        <div>智慧一体化云平台</div>
        <div>
          覆盖固定资产、办公、采购超盘点、维保的全生命周期移动化、数字化管理
        </div>
      </div>
    </div>
    <div class="navBox">
      <el-tabs v-model="activeName" >
        <el-tab-pane label="固定资产" name="fixedassets"
          ><fixed-assets></fixed-assets
        ></el-tab-pane>
        <el-tab-pane label="不动产" name="realestate"
          ><real-estate></real-estate
        ></el-tab-pane>
       
        <el-tab-pane label="无形资产" name="intangibleassets">
          <intangible-assets></intangible-assets
        ></el-tab-pane>

      </el-tabs>
    </div>
  </div>
</template>

<script>
import FixedAssets from "../components/product/FixedAssets";
import RealEstate from "../components/product/RealEstate";

import IntangibleAssets from "../components/product/IntangibleAssets";

export default {
  name: "product",
  components: {
    FixedAssets,
    RealEstate,

    IntangibleAssets,

  },
  data() {
    return {
      activeName: "",
      activeIndex: "1",
      title: "",
      bglist: [
        { id: 0, url: require("@/assets/product/bg1.png") },
        { id: 1, url: require("@/assets/product/bg2.png") },
      ],
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.activeName = route.meta.title;
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.bgBox {
  width: 100%;
  position: relative;
}
.bgBox >>> .el-carousel__container {
  height: 700px;
}
.bgTitle {
  min-width: 700px;
  position: absolute;
  color: #fff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  line-height: 50px;
  text-align: center;
}
.bgTitle > div:nth-child(1) {
  font-size: 48px;
}
.bgTitle > div:nth-child(2) {
  font-size: 14px;
}
.img {
  width: 100%;
  height: 100%;
}
.navBox >>> .el-tabs__nav {
  width: 100%;
  padding:0 414px;
}
.navBox >>> .el-tabs__item {
  height: 80px;
  line-height: 80px;
  color: #000;
  width: calc(100% / 3);
  padding: 0;
  text-align: center;
}

.navBox >>> .el-tabs__nav-wrap::after,
.navBox >>> .el-tabs__active-bar {
  display: none;
}
.navBox >>> .el-tabs__header {
  margin: 0;
  background-color: #f7f9fc;
}
.navBox >>> .el-tabs__item {
  font-size: 24px;
}
.navBox >>> .el-tabs__item.is-active {
  color: #000;
  position: relative;
}
.navBox >>> .el-tabs__item.is-active::after {
  content: "";
  width: 44px;
  height: 8px;
  border-radius: 4px;
  background-color: #88b2f1;
  position: absolute;
  left: 50%;
  top: 50px;
  transform: translateX(-50%);
  z-index: -1;
}
</style>