<template>
  <div class="container">
    <div class="cardBox">
      <div class="mainauto">
        <el-row>
          <el-col :span="11"
            ><div class="grid-content bg-purple industry_introduce">
              <div class="introduce_title">固定资产全生命周期管理</div>

              <ul class="introduce_main">
                <li>
                  从采购入库、领用、变更、维修、调拨、到报废处置，实现固定资产全流程闭环管理。
                </li>
                <li>通过为数据配置自定义字段，让业务可扩展，适应不同场景。</li>
                <li>支持批量导入数据，所有数据也可导出本地备份。</li>
              </ul>
            </div></el-col
          >
          <el-col :span="13"
            ><div class="grid-content bg-purple imgbox">
              <img src="@/assets/product/001.png" alt="" /></div
          ></el-col>
        </el-row>
      </div>
    </div>
    <div class="cardBox">
      <div class="mainauto">
        <el-row>
          <el-col :span="13"
            ><div class="grid-content bg-purple imgbox">
              <img src="@/assets/product/002.png" alt="" /></div
          ></el-col>
          <el-col :span="11"
            ><div class="grid-content bg-purple industry_introduce">
              <div class="introduce_title">
                丰富的分析报表,企业固定资产情况一目了然
              </div>
              <ul class="introduce_main">
                <li>
                  数十个多维度可视化数据报表，既能跟踪每个资产的使用情况，也能全局掌握企业资产状况。
                </li>
                <li>
                  结合客户自身的管理场景，为管理人员设置不同的数据范围权限及报表访问权限。
                </li>
              </ul>
            </div></el-col
          >
        </el-row>
      </div>
    </div>
    <div class="cardBox">
      <div class="mainauto">
        <el-row>
          <el-col :span="11"
            ><div class="grid-content bg-purple industry_introduce">
              <div class="introduce_title">
                RFID、移动扫，多种查询方式全面提升资产使用效率
              </div>

              <ul class="introduce_main">
                <li>电脑端和手机端均可上报维修，查询资产状态。</li>
                <li>可完成盘点任务单，自定义资产盘点范围自动生成盘点、巡检报告，既快又准，从此告别手工计算。</li>
              </ul>
            </div></el-col
          >
          <el-col :span="13"
            ><div class="grid-content bg-purple imgbox">
              <img src="@/assets/product/003.png" alt="" /></div
          ></el-col>
        </el-row>
      </div>
    </div>
    <div class="cardBox">
      <div class="mainauto">
        <el-row>
          <el-col :span="13"
            ><div class="grid-content bg-purple imgbox">
              <img src="@/assets/product/004.png" alt="" /></div
          ></el-col>
          <el-col :span="11"
            ><div class="grid-content bg-purple industry_introduce">
              <div class="introduce_title">
                自定义流程引擎，全面支持资产管理审批流程
              </div>
              <ul class="introduce_main">
                <li>支持资产领用、调拨、清理、变更、申请等全部业务流程审批。</li>
                <li>
                  用户可通过管理员受理某权限，基于管理员配置各功能权限完成各自的工作任务。
                </li>
              </ul>
            </div></el-col
          >
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "fixed-assets",
};
</script>

<style scoped>
.container {
  margin-top: 56px;
  width: 100%;
}
.cardBox {
  width: 100%;

  margin-bottom: 40px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.mainauto {
  width: 1200px;
  margin: 0 auto;
}
.grid-content {
  height: 426px;
}
.imgbox {
  text-align: right;
  padding-right: 90px;
}
.imgbox img {
  width: 170px;
  height:284px;
  margin-top: 60px;
}
.industry_introduce {
  padding: 40px 0 0 90px;
  background: #fff;
  color: #333;
}
.cardBox:nth-child(2n) .mainauto .el-row .industry_introduce {
  padding: 40px 0 0 56px;
}
.introduce_main {
  font-size: 20px;
}
.introduce_main li {
  position: relative;
  padding-left: 20px;
  line-height: 26px;
  margin-bottom: 20px;
}
.introduce_main li::before {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  background: #333;
  border-radius: 50%;
  left: 0;
  top: 10px;
}

.cardBox:nth-child(2n) .mainauto .el-row .imgbox {
  text-align: left;
  padding: 0 0 0 90px;
}
.introduce_title {
  font-size: 30px;
  margin-bottom: 32px;
}
.container .cardBox:last-child{
    height:630px;
    margin-bottom:0;
     background: url("../../assets/index/bottom_bg.png") no-repeat bottom;
   background-size: 100% 50%;
}
</style>