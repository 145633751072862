<template>
  <div class="container">
    <div class="cardBox">
      <div class="mainauto">
        <el-row>
          <el-col :span="11"
            ><div class="grid-content bg-purple industry_introduce">
              <div class="introduce_title">
                线上文件、档案、视频一站式管理服务
              </div>

              <ul class="introduce_main">
                <li>
                  支持用户基础操作下载、打印表格等。
                  授权可根据人、部门、岗位、角色等多维度设置。
                </li>
                <li>
                  所有软件资产统一管理，软件上线后状态全流程管理。软件使用人一键查看。
                </li>
              </ul>
            </div></el-col
          >
          <el-col :span="13"
            ><div class="grid-content bg-purple imgbox">
              <img class="small-img" src="@/assets/product/005.png" alt="" /></div
          ></el-col>
        </el-row>
      </div>
    </div>
    <div class="cardBox">
      <div class="mainauto">
        <el-row>
          <el-col :span="13"
            ><div class="grid-content bg-purple imgbox">
              <img src="@/assets/product/007.png" alt="" /></div
          ></el-col>
          <el-col :span="11"
            ><div class="grid-content bg-purple industry_introduce">
              <div class="introduce_title">
                资源池灵活配置,无缝对接审批流程,提升企业效益
              </div>
              <ul class="introduce_main">
                <li>
                  实现资源池信息化管理，一键查看资源下载明细。实时了解资源安全。
                </li>
                <li>线上文件，档案，视频等资源统一管理。</li>
              </ul>
            </div></el-col
          >
        </el-row>
      </div>
    </div>
    <div class="cardBox">
      <div class="mainauto">
        <el-row>
          <el-col :span="11"
            ><div class="grid-content bg-purple industry_introduce">
              <div class="introduce_title">
                主数据管理,满足不同场景的业务需要
              </div>

              <ul class="introduce_main">
                <li>
                  —体化的数据治理和共享平台，确保数据资源中心的数据质量和安全管理。
                </li>
                <li>统一员工和组织主数据，对所有系统的员工和组织进行规范。</li>
              </ul>
            </div></el-col
          >
          <el-col :span="13"
            ><div class="grid-content bg-purple imgbox">
              <img class="smallimg" src="@/assets/product/008.png" alt="" /></div
          ></el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "intangible-assets",
};
</script>

<style scoped>
.container {
  margin-top: 56px;
  width: 100%;
}
.cardBox {
  width: 100%;

  margin-bottom: 40px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.mainauto {
  width: 1200px;
  margin: 0 auto;
}
.grid-content {
  height: 426px;
}
.imgbox {
  text-align: right;
  padding-right: 90px;
}
.imgbox img {
  width: 170px;
  height:284px;
  margin-top: 60px;
}
.imgbox .small-img{
  width:240px;
}
.imgbox .smallimg{
  width:240px;height:270px;
}
.industry_introduce {
  padding: 40px 0 0 90px;
  background: #fff;
  color: #333;
}
.cardBox:nth-child(2n) .mainauto .el-row .industry_introduce {
  padding: 40px 0 0 56px;
}
.introduce_main {
  font-size: 20px;
}
.introduce_main li {
  position: relative;
  padding-left: 20px;
  line-height: 26px;
  margin-bottom: 20px;
}
.introduce_main li::before {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  background: #333;
  border-radius: 50%;
  left: 0;
  top: 10px;
}

.cardBox:nth-child(2n) .mainauto .el-row .imgbox {
  text-align: left;
  padding: 0 0 0 90px;
}
.introduce_title {
  font-size: 30px;
  margin-bottom: 32px;
}
.container .cardBox:last-child {
  height: 630px;
  margin-bottom: 0;
  background: url("../../assets/index/bottom_bg.png") no-repeat bottom;
  background-size: 100% 50%;
}
</style>
